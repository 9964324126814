import React from 'react';
import App from './App.jsx'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'


createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App></App>
  </StrictMode>
)